<template>
  <div>
    <div class="nav">
      <div>
        <div v-if="searchShow" class="search">
          <el-input 
            placeholder="请输入球员中文或英文名称" v-model="searchTxt" 
            class="input" @keyup.enter.native="btnSearch()"
          >
          </el-input>
          <div @click="btnSearch()" class="append">搜索</div>
          <!-- <div @click="searchShow = !searchShow">
            <img src="../../../assets/images/close.png" alt="" class="closeBtn" />
          </div> -->
        </div>
        <!-- <div v-else @click="searchShow = !searchShow" class="searchElse">
          <img src="../../../assets/images/search.png" alt="" class="searchBtn" />
        </div> -->
      </div>
      <!-- <div class="offical">官方排名</div> -->
    </div>
    <!-- <div class="mx-30">全部球员排名</div> -->
    <div class="WorldRanking">
      <div class="top">
          <div class="__title">
          <span>联邦快递杯积分榜</span>
        </div>
      </div>
      <!--    表格数据-->
      <div class="__list">
        <div class="__top" v-if="false">
          <span class="iconfont icon-sanjiaoyou"></span>
          <span>美巡平均：</span>
          <span>$10000</span>
        </div>
        <div class="_list">
          <div class="list_child">
            <div class="line01">本周排名</div>
            <div class="line01">上周排名</div>
            <div class="line02">球员名称</div>
            <div class="line03">参赛次数</div>
            <div class="line04">积分</div>
            <div class="line04">获胜次数</div>
            <div class="line04">前十次数</div>
            <!-- <div class="line04">领先优势</div> -->
            <div class="line04">离榜首差距</div>
            <div class="line04">重置点</div>
          </div>
          <div v-if="list && list.length">
            <div class="list_child list_tr_child"
                :class="{'bg':index%2===0}"
                v-for="(item, index) in list" :key="index">
              <div class="line01">{{item.curRank}}</div>
              <div class="line01">{{item.prevRank}}</div>
              <div class="line02 line02_other">
                <span @click="gotoPlayerDetail(item)" class="gotoPlayerDetail">{{item.player_name}}</span>
                <br/><span class="plaer_nameEn">{{item.e_name}}</span>
              </div>
              <div class="line03">{{item.statValues.rndEvents}}</div>
              <div class="line04">{{item.statValues.statValue1}}</div>
              <div class="line04">{{item.statValues.statValue2}}</div>
              <div class="line04">{{item.statValues.statValue3}}</div>
              <div class="line04">{{item.statValues.statValue4}}</div>
              <div class="line04">{{item.statValues.statValue5}}</div>
            </div>
          </div>
          <div v-else class="searchEmpty">
            暂无搜索结果
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import pgaApi from "../../../request/pgaApi";
  export default {
    components: {
      
    },
    data () {
      return {
        searchShow: true,
        searchTxt: '',
        isShow_match:false,
        list:[],
        middleList: [],
      }
    },
    methods: {
      btnSearch() {
        console.log(this.searchTxt);
        if(this.searchTxt && this.searchTxt !== '') {
          let searchText = this.searchTxt;
          searchText = searchText.toLocaleLowerCase();
          this.list = this.middleList.filter(item => {
            if(item.player_name.includes(searchText) || item.e_name.toLowerCase().includes(searchText)) {
              return item;
            }
          })
        } else {
          this.list = this.middleList;
        }
      },
      async getList(){
        let data = await pgaApi({
          url:'/getPgaSta',
          data:{
            year:2023,
            staId:'02671'
          }
        })
        if(data!==null){
          this.list = data.sta;
          this.middleList = JSON.parse(JSON.stringify(this.list));
        } 
      
      },
      gotoPlayerDetail(item) {
        console.log(item);
        if(item.playerId) {
          this.$router.push({ 
            path: '/PlayerInter/PlayerInter?',
            query: {
              playerId: item.playerId,
            }
          })
        }
      },
    },
    created() {
      this.getList()
    },
  }
</script>


<style lang="less" scoped>
  .nav {
    display: flex;
    align-items: center;
  }
  .search {
    display: flex;
    align-items: center;
    .input {
      min-width: 200px;
      height: 50px;
      /deep/ .el-input__inner {
        height: 100%;
        border-radius: 0;
      }
    }
    .append {
      background: #00284B;
      height: 50px;
      color: white;
      padding: 0 20px;
      min-width: 50px;
      line-height: 3;
      text-align: center;
      cursor: pointer;
    }
  }
  .searchElse {
    padding: 10px 20px;
    border: 1px solid #999999;
    cursor: pointer;
  }
  .searchBtn {
    background-image: none;
    width: 24px;
    height: 24px;
  }
  .closeBtn {
    background-image: none;
    width: 40px;
    height: 40px;
    cursor: pointer;
    margin-left: 5px;
  }
  .offical {
    border-radius: 150px;
    background: rgba(0, 51, 102, 1);
    color: white;
    padding: 15px 40px;
    margin-left: 1rem;
    cursor: pointer;
  }
  .mx-30 {
    margin: 30px 0;
    font-size: 18px;
    color: #00284B;
    font-weight: bold;
  }

  
  .WorldRanking{
    width: 1225px;
    padding-top: 40px;
    .top{
      padding: 10px 10px 10px 0px;
      .__title{
        font-size: 30px;
        span{
          //font-family: My_Bold;
          font-weight: 500;
        }
      
      }
    }

    .__list{
      padding-top: 20px;
      padding-bottom: 20px;
      .__top{
        padding-left: 30px;
        font-size: 16px;
        padding-bottom: 20px;
        span{
          //font-family: My_Medium;
        }
        span:nth-child(2){
          //font-family: My_Medium;
          font-weight: 600;
        }
      }

      ._list{
        font-size: 16px;
        .list_child{
          display: flex;
          text-align: center;
          line-height: 50px;
          border: 1px solid #eeeeee;
          .line01{
            //font-family: My_Medium;
            width: 120px;
            border-right: 1px solid #eeeeee;
          }
          .line02{
            //font-family: My_Medium;;
            width: 400px;
            padding-left: 30px;
            text-align: left;
            border-right: 1px solid #eeeeee;
          }
          .line02_other{
            //font-family: My_Medium;
            color: #003e7e;
          }
          .gotoPlayerDetail {
            cursor: pointer;
          }
          .line03{
            //font-family: My_Medium;
            width: 160px;
            border-right: 1px solid #eeeeee;
          }
          .line04{
            //font-family: My_Medium;
            width: 235px;
            border-right: 1px solid #eeeeee;
          }
          .line05{
            //font-family: My_Medium;
            width: 109px;
            /*border-right: 1px solid #000000;*/
          }
          .plaer_nameEn{
            color: #333333;
            font-weight: 600;
          }
        }
        .list_child:nth-child(1){
          background-image: linear-gradient(#ffffff 25px, #ebebeb);
          div{
            //font-family: My_Medium;
          }
        }
        .bg{
          background-color: #f2f2f2;
        }
      }

      ._list{
        .list_tr_child{
          line-height: 35px !important;
          padding-top: 5px;
        }

        .bg{
          background-color: #f2f2f2;
        }
      }
    }
  }
  .searchEmpty {
    width: 100%;
    text-align: center;
    padding: 20px;
  }
</style>